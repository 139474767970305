
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.tab-contents{
    margin-top: 50px;
    .item { transition: all 0.15s ease; background: #fff; }
}
.v-leave-active { position: absolute; }
.v-enter { opacity: 1; }
.v-leave-to { opacity: 0; }

.fair-view{
    &__head{
        display: flex; margin-bottom: 80px; font-size: 14px; color: #999;

        .tit { margin: 2px 0 12px; color: $color-primary; }
        .txt { font-size: 18px; color: #666666; }

        &-thumb { overflow: hidden; flex: 0 0 350px; height: 250px; background-color: #f8f8f8; border: 1px solid $border; border-radius: 10px; }
        &-thumb img { display: block; width: 100%; height: auto; }
        &-contents {
            flex: 1 1 auto; display: flex; flex-direction: column; justify-content: center; margin-left: 25px;
            border-top: 1px solid $border-dark; border-bottom: 1px solid $border;
        }
    }
    &__buttons{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 0 0 150px;
        border-top: 1px solid $border-dark; border-bottom: 1px solid $border;
        .button {
            margin: 5px;
            width: 100%;
        }
    }
}
@media(max-width:1200px){
    
}
@media(max-width:768px){
    .fair-view{
        &__head{
            flex-direction: column; font-size: 13px;
            .tit { margin: 6px 0 10px; }
            .txt { font-size: 16px; color: #666666; }
            &-thumb { flex-basis: auto; position: relative; padding-top: 100%; height: 0; }
            &-thumb img { position: absolute; top: 0; left: 0; height: 100%; object-fit: cover; }
            &-contents {
                margin: 30px 0 0; border-top: none; border-bottom: none;
            }
        }
    }
}
