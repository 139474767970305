
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.fair-product-view {
    .v-btn:not(.v-btn--fab) {
        padding-right: 40px;
        padding-left: 40px;
    }
    &__head {
        display: flex;
        .subtit {
            font-size: 24px;
            color: #666;
        }
        &-thumb {
            overflow: hidden;
            position: relative;
            width: 450px;
            height: 450px;
            border: 1px solid $border;
            border-radius: 20px;
        }
        &-thumb .swiper-container {
            height: 100%;
        }
        &-thumb .swiper-container .thumb {
            height: 100%;
        }
        &-thumb .swiper-container .thumb img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &-thumb .swiper-pagination {
            bottom: 20px;
        }
        &-contents {
            flex: 1 1;
            margin-left: 60px;
            padding: 20px 0;
        }
        &-buttons {
            display: flex;
            margin: 0 -5px;
        }
        &-buttons .v-btn {
            margin: 0 5px;
        }
    }
    ::v-deep &__info {
        margin: 40px 0;
        padding: 35px 0;
        font-size: 18px;
        color: #666666;
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;

        li {
            display: flex;
            margin-bottom: 15px;
        }
        li:last-child {
            margin-bottom: 0;
        }
        li .tit {
            flex: 0 0 130px;
            font-size: inherit;
        }
        li .txt {
            flex: 1 1;
        }
        li a {
            color: #000cff;
        }
    }
    &__body {
        margin-top: 50px;
        .tit {
            margin: 10px 0;
            font-size: 18px;
        }
    }

    ::v-deep .feature-table {
        display: table;
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
        line-height: 1.8;
        font-size: 14px;
        color: #999999;
        border-top: 1px solid $border-dark;
        &__row {
            display: table-row;
            border-bottom: 1px solid $border;
        }
        &__th {
            display: table-cell;
            padding: 20px;
            width: 210px;
            color: #222222;
        }
        &__td {
            display: table-cell;
            padding: 20px;
            height: 65px;
        }
    }
}
@media (max-width: 1200px) {
    .fair-product-view {
        &__head {
            .subtit {
                font-size: 18px;
            }
            &-thumb {
                width: 360px;
                height: 360px;
            }
            &-contents {
                margin-left: 50px;
            }
        }
        ::v-deep &__info {
            margin: 30px 0;
            padding: 25px 0;
            font-size: 16px;
            li {
                margin-bottom: 12px;
            }
            li .tit {
                flex: 0 0 110px;
            }
        }
    }
}
@media (max-width: 768px) {
    .v-btn:not(.v-btn--fab) {
        flex: 1 1;
    }
    .fair-product-view {
        &__head {
            flex-direction: column;
            .subtit {
                font-size: 16px;
            }
            &-thumb {
                padding-top: 60%;
                width: 100%;
                height: 0;
            }
            &-thumb img {
                position: absolute;
                top: 0;
                left: 0;
            }
            &-contents {
                margin: 25px 0 0;
                padding: 0;
            }
        }
        ::v-deep &__info {
            margin: 20px 0;
            padding: 18px 0;
            font-size: 14px;
            li {
                margin-bottom: 10px;
            }
            li .tit {
                flex: 0 0 80px;
            }
        }
        ::v-deep .feature-table {
            font-size: 13px;
            &__th {
                width: 160px;
            }
        }
    }
}
@media (max-width: 500px) {
    .fair-product-view {
        ::v-deep .feature-table {
            &__th {
                padding: 10px;
                width: 120px;
            }
            &__td {
                padding: 14px 10px;
            }
        }
    }
}
